import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import {Settings} from '@app/02-core/models/settings.model';
import {SettingsService} from '@app/02-core/services/settings.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {UsersService} from '@app/02-core/services/users.service';
import {MainService} from '@app/02-core/services';

@Component({
  selector: 'app-dialog',
  template: `
    <h4 mat-dialog-title>Změna hesla</h4>
    <mat-dialog-content>
      <form [formGroup]="passwordForm">
        <p>Nejdříve zadejte vaše aktuální heslo.</p>
        <div class="form-row">
          <mat-form-field>
            <input matInput placeholder="Aktuální heslo" type="password" formControlName="oldPassword" name="newPassword" />
          </mat-form-field>
        </div>
        <p>Nyní zadejte nové heslo dle následujících pravidel:</p>
        <p style="font-weight: 500;">{{ settings.passwordRules.description }}</p>
        <div class="form-row">
          <mat-form-field>
            <input matInput placeholder="Nové heslo" [type]="showPassword ? 'text' : 'password'" name="newPassword" formControlName="password" />
            <mat-icon style="cursor: pointer" matSuffix (click)="showPassword = !showPassword">visibility</mat-icon>
            <mat-hint align="end">{{ passwordForm.get('password').value.length }}</mat-hint>
          </mat-form-field>
        </div>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button type="button" mat-button color="warn" (click)="dialogRef.close()">Zrušit</button>
      <button type="button" mat-raised-button color="accent" (click)="save()" [disabled]="passwordForm.pristine">OK</button>
    </mat-dialog-actions>
  `,
})
export class PasswordDialogComponent {

  public settings: Settings;
  public passwordForm: UntypedFormGroup;
  public showPassword = false;

  constructor(
    public dialogRef: MatDialogRef<PasswordDialogComponent>,
    private settingsService: SettingsService,
    private mainService: MainService,
    private usersService: UsersService,
    private fb: UntypedFormBuilder
  ) {
    this.settings = this.settingsService.$settings.getValue();
    this.passwordForm = this.fb.group({
      oldPassword: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.pattern(this.settings.passwordRules?.regExp)]]
    });
  }

  async save(): Promise<void> {
    if (this.passwordForm.valid) {
      try {
        await this.usersService.changePassword(this.passwordForm.value);
        this.mainService.showSuccess('Heslo bylo změněno.');
        this.dialogRef.close();
      } catch (e) {
        this.mainService.showError('Heslo nebylo změněno.');
      }
    } else {
      this.passwordForm.markAllAsTouched();
    }
  }
}
