import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StyleManagerService {
  private isDark = false;

  protected $isDark: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isDark$: Observable<boolean> = this.$isDark.asObservable();

  constructor(
  ) {
    this.isDark = localStorage.getItem('darkTheme') ? localStorage.getItem('darkTheme') === '1' : window.matchMedia('(prefers-color-scheme: dark)').matches || false;
    this.updateTheme();
  }

  toggleDarkTheme(): void {
    this.isDark = !this.isDark;
    this.updateTheme();
  }

  private updateTheme(): void {
    if (!this.isDark) {
      this.removeStyle('dark-theme');
    } else {
      const href = 'dark-theme.css';
      getLinkElementForKey('dark-theme').setAttribute('href', href);
    }
    localStorage.setItem('darkTheme', this.isDark ? '1' : '0');
    this.$isDark.next(this.isDark);
  }

  private removeStyle(key: string): void {
    const existingLinkElement = getExistingLinkElementByKey(key);
    if (existingLinkElement) {
      document.head.removeChild(existingLinkElement);
    }
  }
}

function getLinkElementForKey(key: string): any {
  return getExistingLinkElementByKey(key) || createLinkElementWithKey(key);
}

function getExistingLinkElementByKey(key: string): any {
  return document.head.querySelector(
    `link[rel="stylesheet"].${getClassNameForKey(key)}`
  );
}

function createLinkElementWithKey(key: string): any {
  const linkEl = document.createElement('link');
  linkEl.setAttribute('rel', 'stylesheet');
  linkEl.classList.add(getClassNameForKey(key));
  document.head.appendChild(linkEl);
  return linkEl;
}

function getClassNameForKey(key: string): string {
  return `style-manager-${key}`;
}
