import {Injectable, isDevMode} from '@angular/core';
import {Settings} from '@app/02-core/models/settings.model';
import {BehaviorSubject, Observable} from 'rxjs';
import {CrudService} from '@app/02-core/services/crud.service';

@Injectable({providedIn: 'root'})
export class SettingsService extends CrudService {

  // Subjects
  public $settings: BehaviorSubject<Settings> = new BehaviorSubject(null);

  // Observables of Subjects
  public settings$: Observable<Settings> = this.$settings.asObservable();

  constructor(
  ) {
    super('settings');
  }

  public async load(): Promise<void> {
    const settings = await this.find();
    this.$settings.next(settings);
  }

  public async save(data): Promise<void> {
    const settings = await this.patch(null, data);
    this.$settings.next(settings);
  }
}
