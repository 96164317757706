<div class="header" fxFlex=" 1 1 auto" fxLayout="row" fxLayoutAlign="start center">
  <h1><a href="." target="_self">OSTŘÍŽ</a></h1>
  <h2 *ngIf="router.isActive('/events', false)" >Akce</h2>
  <h2 *ngIf="router.isActive('/devices', false)" >Zařízení</h2>
  <div fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="end center">
    <span *ngIf="diskSpace" class="size-info" [ngClass]="{warn: freeSpace < 10000000000 }">K dispozici <b>{{freeSpace | bytesConvert}}</b> z {{diskSpace | bytesConvert}}</span>
    <button mat-icon-button [routerLink]="['/events/add']" title="Vytvořit akci"><mat-icon>add</mat-icon></button>
    <button mat-icon-button title="Tmavý režim" (click)="toggleDarkMode()" *ngIf="!(styleManagerService.isDark$ | async)"><mat-icon>nightlight</mat-icon></button>
    <button mat-icon-button title="Světlý režim" (click)="toggleDarkMode()" *ngIf="styleManagerService.isDark$ | async"><mat-icon>light_mode</mat-icon></button>
    <button mat-icon-button [mat-menu-trigger-for]="userMenu"><mat-icon>more_horiz</mat-icon></button>
  </div>
</div>
<mat-menu #userMenu="matMenu">
  <div mat-menu-item class="title">
    <mat-icon>person</mat-icon>
    <span>{{ (authService.user$ | async).name }}</span>
  </div>
  <button mat-menu-item [routerLink]="['/events']">
    <mat-icon>video_camera_front</mat-icon>
    <span>Akce</span>
  </button>
  <button mat-menu-item [routerLink]="['/devices']" *ngIf="(authService.user$ | async).isAdmin">
    <mat-icon>videocam</mat-icon>
    <span>Zařízení</span>
  </button>
  <mat-divider></mat-divider>
  <button mat-menu-item (click)="changePassword()">
    <mat-icon color="accent">lock</mat-icon>
    <span>Změna hesla</span>
  </button>
  <button mat-menu-item (click)="logout()">
    <mat-icon color="warn">exit_to_app</mat-icon>
    <span>Odhlásit se</span>
  </button>
</mat-menu>
