import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from '@app/01-layout/layout/layout.component';
import {AdminGuard, MainLoginGuard} from '@app/02-core/guards';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [MainLoginGuard],
    children: [
      {
        path: 'events',
        loadChildren: () => import('./03-modules/events/events.module').then(m => m.EventsModule)
      },
      {
        path: 'devices',
        loadChildren: () => import('./03-modules/devices/devices.module').then(m => m.DevicesModule),
        canActivate: [AdminGuard]
      },
      {
        path: '',
        redirectTo: 'events',
        pathMatch: 'full'
      },
      {
        path: '**',
        redirectTo: 'events',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: '',
    redirectTo: 'events',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'events',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
