import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import {ChatItemInterface} from '@app/02-core/interfaces/chat-item.interface';
import {BookmarkDialogComponent} from '@app/03-modules/dialogs/bookmark-dialog.component';
import {ConfirmDialogComponent} from '@app/03-modules/dialogs/confirm-dialog.component';
import {PasswordDialogComponent} from '@app/03-modules/dialogs/password-dialog.component';
import {ExportDialogComponent} from '@app/03-modules/dialogs/export-dialog.component';
import {AlertDialogComponent} from '@app/03-modules/dialogs/alert-dialog.component';

@Injectable()
export class DialogService {
  public defaultDialogConfig: MatDialogConfig = {
    disableClose: false
  };

  constructor(
    private dialog: MatDialog
  ) {
  }

  public bookmark(chatItem: ChatItemInterface): Promise<ChatItemInterface> {
    const dialogConfig: MatDialogConfig = {
      ...this.defaultDialogConfig,
      data: {
        chatItem
      }
    };
    return this.dialog.open(BookmarkDialogComponent, dialogConfig).afterClosed().toPromise();
  }

  public export(options: any[], targets: any[]): Promise<any> {
    const dialogConfig: MatDialogConfig = {
      ...this.defaultDialogConfig,
      data: {
        options,
        targets
      }
    };
    return this.dialog.open(ExportDialogComponent, dialogConfig).afterClosed().toPromise();
  }

  public confirm(title: string, message: string): Promise<boolean> {
    const dialogConfig: MatDialogConfig = {
      ...this.defaultDialogConfig,
      data: {
        title,
        message
      }
    };
    return this.dialog.open(ConfirmDialogComponent, dialogConfig).afterClosed().toPromise();
  }

  public alert(title: string, message: string): Promise<boolean> {
    const dialogConfig: MatDialogConfig = {
      ...this.defaultDialogConfig,
      data: {
        title,
        message
      }
    };
    return this.dialog.open(AlertDialogComponent, dialogConfig).afterClosed().toPromise();
  }

  public password(): Promise<void> {
    const dialogConfig: MatDialogConfig = {
      ...this.defaultDialogConfig
    };
    return this.dialog.open(PasswordDialogComponent, dialogConfig).afterClosed().toPromise();
  }


}
