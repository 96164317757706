<svg class="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102.87 102.87"><g id="Vrstva_2" data-name="Vrstva 2"><g id="Vrstva_1-2" data-name="Vrstva 1"><path d="M51.43,0a51.44,51.44,0,1,0,51.44,51.43A51.44,51.44,0,0,0,51.43,0Zm0,97.55A46.12,46.12,0,1,1,97.55,51.43,46.12,46.12,0,0,1,51.43,97.55Z"/><path d="M101.92,51.59c-25,1.42-11.08,3.35-39.06,13.07C47.48,70,45.33,73,43.2,87.46,33,84.53,25.8,79.89,23.58,72.34,21.41,65,25.1,58.79,33,53.56l4.79,4.06c2-6.57,3.78-12.68,5.61-18.79l-1-.58L22.94,54.76A26,26,0,0,1,28,36.24C36.45,24.53,54.7,17,75.35,8.84,104.83,23,94,50.83,102.87,51.43"/></g></g></svg>
<h1><a href="." target="_self">OSTŘÍŽ</a></h1>
<div class="login-wrap">
  <form (submit)="login()">
    <div class="form-row">
      <mat-form-field>
        <input matInput placeholder="Přihlašovací jméno" autocomplete="username" [(ngModel)]="username" name="username" />
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field class="password">
        <input matInput placeholder="Heslo" [type]="hidePassword ? 'password' : 'text'"  autocomplete="current-password" [(ngModel)]="password" name="password" />
        <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-pressed]="hidePassword" type="button">
          <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-row">
      <button mat-flat-button color="accent" type="submit">Přihlásit se</button>
    </div>
  </form>
</div>
<p class="footer">verze {{ version }} | &copy; Audiopro s.r.o.</p>
