import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService, MainService} from '@app/02-core/services';
import {ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import {CheckSpaceService} from '@app/02-core/services/checkspace.service';
import {StyleManagerService} from '@app/02-core/services/style-manager.service';
import {DialogService} from '@app/03-modules/dialogs/dialog.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public activeRoute: string;
  public diskSpace: any = 0;
  public freeSpace: any = 0;

  constructor(
    public mainService: MainService,
    private route: ActivatedRoute,
    public router: Router,
    public authService: AuthService,
    private dialogsService: DialogService,
    public styleManagerService: StyleManagerService,
    private checkSpaceService: CheckSpaceService
  ) {

  }

  ngOnInit(): void {
    this.updateSpace();
    setInterval(() => {
      this.updateSpace();
    }, 30000);
  }

  ngOnDestroy(): void {
  }

  async logout(): Promise<void> {
    await this.authService.logout();
    await this.router.navigate(['/login']);
  }

  async changePassword(): Promise<void> {
    const password = await this.dialogsService.password();
  }

  public toggleDarkMode(): void {
    this.styleManagerService.toggleDarkTheme();
  }

  private async updateSpace(): Promise<void> {
    const diskSpace = await this.checkSpaceService.find();
    this.freeSpace = diskSpace.free;
    this.diskSpace = diskSpace.size;
  }

}
