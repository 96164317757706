import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ChatItemInterface} from '@app/02-core/interfaces/chat-item.interface';

@Component({
  selector: 'app-dialog',
  template: `
    <h4 mat-dialog-title>Záložka</h4>
    <mat-dialog-content>
      <p>záložka</p>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button type="button" mat-button color="warn" (click)="dialogRef.close(null)" *ngIf="!dialogRef.disableClose"><span>Zrušit</span></button>
      <button type="button" mat-raised-button color="accent" (click)="dialogRef.close(null)"><span>Uložit</span></button>
    </mat-dialog-actions>
  `,
})
export class BookmarkDialogComponent {

  public form: UntypedFormGroup;
  public chatItem: ChatItemInterface;

  constructor(
    public dialogRef: MatDialogRef<BookmarkDialogComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {

  }


}
