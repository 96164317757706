import { Component } from '@angular/core';
import {ApiService} from '@app/02-core/services';
import {StyleManagerService} from '@app/02-core/services/style-manager.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Ostriz';

  constructor(
    private styleManagerService: StyleManagerService,
    public apiService: ApiService
  ) {
  }
}
