import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'bytesConvert' })
export class BytesConvertPipe implements PipeTransform {
  constructor(
  ) {}
  transform(bytes: number): string {
    if (!bytes) {
      return '0 B';
    }
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    return ((bytes / Math.pow(1024, i)) as any).toFixed(2) * 1 + ' ' + sizes[i];
  }
}
