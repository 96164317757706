import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import {AuthService} from '@app/02-core/services';
import {SettingsService} from '@app/02-core/services/settings.service';

@Injectable()
export class MainLoginGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private settingsService: SettingsService
  ) {

  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async (resolve) => {
      const authenticated = await this.authService.reLogin();
      if (authenticated) {
        await this.settingsService.load();
        resolve(true);
      } else {
        resolve(false);
        await this.router.navigate(['/login']);
      }
    });
  }
}

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {

  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async (resolve) => {
      const authenticated = await this.authService.reLogin();
      if (!authenticated) {
        resolve(true);
      } else {
        resolve(false);
        await this.router.navigate(['/']);
      }
    });
  }
}
