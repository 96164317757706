import {Injectable} from '@angular/core';
import {User, UserGroup} from '@app/02-core/models/user.model';
import {BehaviorSubject, Observable} from 'rxjs';
import {ApiService} from '@app/02-core/services/api.service';

@Injectable({providedIn: 'root'})
export class AuthService {

  // Subjects
  private $authenticated: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public $user: BehaviorSubject<User> = new BehaviorSubject(null);

  // Observables of Subjects
  public authenticated$: Observable<boolean> = this.$authenticated.asObservable();
  public user$: Observable<User> = this.$user.asObservable();

  public accessToken: string;

  constructor(
    private apiService: ApiService
  ) {
  }

  public login(login: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.authenticate({
        strategy: 'local',
        login,
        password
      }).then((response: any) => {
        this.setUser(response.user);
        this.setAuthenticated(true);
        resolve(true);
      }).catch((error: any) => {
        return this.apiService.authenticate({
          strategy: 'ldap',
          login,
          password
        }).then((response: any) => {
          this.accessToken = response.accessToken;
          this.setUser(response.user);
          this.setAuthenticated(true);
          resolve(true);
        }).catch((error2: any) => {
          this.setAuthenticated(false);
          reject(error2);
        });
      });
    });
  }

  public async logout(): Promise<void> {
    await this.apiService.logout();
    this.setAuthenticated(false);
    this.setUser(null);
    this.accessToken = null;
  }

  public reLogin(): Promise<boolean> {
    return new Promise((resolve) => {
      this.apiService.reAuthenticate().then((response: any) => {
        this.accessToken = response.accessToken;
        this.setUser(response.user);
        this.setAuthenticated(true);
        resolve(true);
      }).catch((e) => {
        this.setAuthenticated(false);
        resolve(false);
      });
    });
  }

  private setAuthenticated(authenticated: boolean): void {
    if (this.$authenticated.getValue() !== authenticated) {
      this.$authenticated.next(authenticated);
    }
  }

  private setUser(user: User): void {
    if (user) {
      user.isAdmin = user.groups?.some((g: UserGroup) => g.isAdmin);
      user.isOwner = user.groups?.some((g: UserGroup) => g.isOwner);
    }
    this.$user.next(user);
  }

}
