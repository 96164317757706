import { environment } from '@env/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import * as io from 'socket.io-client';
import feathers, {Service} from '@feathersjs/feathers';
import feathersSocketIoClient from '@feathersjs/socketio-client';
import feathersAuthentication from '@feathersjs/authentication-client';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class ApiService {
  private app: any;
  readonly socket: any;
  private offlineTimer: any;
  private willRefresh = false;

  // Subjects
  private $connected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  // Observables
  public connected$: Observable<boolean> = this.$connected.asObservable();

  constructor() {
    this.offlineTimer = setTimeout(() => {
      this.$connected.next(false);
      this.willRefresh = true;
    }, 1000);
    this.socket = io(environment.backendUrl);
    this.socket.on('connect', () => {
      clearTimeout(this.offlineTimer);
      if (this.willRefresh) {
        location.reload();
      }
      this.$connected.next(true);
    });
    this.socket.on('disconnect', (reason) => {
      clearTimeout(this.offlineTimer);
      this.$connected.next(false);
      this.willRefresh = true;
    });
    this.app = feathers()
      .configure(feathersSocketIoClient(this.socket))
      .configure(feathersAuthentication({
        storage: window.localStorage
      }));
  }

  public service(item: string): Service<any> {
    return this.app.service(item);
  }

  public authenticate(credentials?): Promise<any> {
    return this.app.authenticate(credentials);
  }

  public reAuthenticate(): Promise<any> {
    return this.app.reAuthenticate();
  }

  public async logout(): Promise<void> {
    await this.app.authentication.logout();
    this.app.logout();
  }
}
