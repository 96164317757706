import {Component, OnDestroy, OnInit} from '@angular/core';
import {MainService} from '@app/02-core/services';
import {ActivatedRoute, Router, NavigationEnd} from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
  public isProducts = false;

  constructor(
    public mainService: MainService,
    private route: ActivatedRoute,
    private router: Router
  ) {

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

}
