import { Injectable } from '@angular/core';
import {User} from '@app/02-core/models/user.model';
import {AuthService, DataService} from '@app/02-core/services/index';

@Injectable({providedIn: 'root'})
export class UsersService extends DataService<User> {

  constructor(
    private authService: AuthService
  ) {
    super('users');
    this.query = {
      login: {$ne: 'admin'},
      $sort: {name: 1}
    };
    this.authService.authenticated$.subscribe((authenticated) => {
      if (authenticated) {
        this.load(0).then();
      }
    });
  }

  async changePassword(data: any): Promise<void> {
    return this.patch(this.authService.$user.getValue()._id, data);
  }
}

