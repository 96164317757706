import { Injectable } from '@angular/core';
import {CrudService} from '@app/02-core/services/crud.service';

@Injectable({providedIn: 'root'})
export class CheckSpaceService extends CrudService {

  constructor(
  ) {
    super('media/check-space');
  }

}

