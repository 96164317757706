import {
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
} from '@angular/core';
import { fromEvent, merge, of, Subscription, timer } from 'rxjs';
import {filter, first, map, switchMap} from 'rxjs/operators';

@Directive({
  selector: '[longPress]',
})
export class LongPressDirective implements OnDestroy {
  private eventSubscribe: Subscription;
  private eventSubscribeOff: Subscription;
  threshold = 1000;

  @Output()
  longPress = new EventEmitter();

  constructor(private elementRef: ElementRef) {
    const mousedown = fromEvent<MouseEvent>(
      elementRef.nativeElement,
      'mousedown'
    ).pipe(
      filter((event) => event.button === 0), // Only allow left button (Primary button)
      map((event) => true) // turn on threshold counter
    );
    const touchstart = fromEvent(elementRef.nativeElement, 'touchstart', {passive: true}).pipe(
      map(() => true)
    );
    const touchEnd = fromEvent(elementRef.nativeElement, 'touchend', {passive: true}).pipe(
      map(() => false)
    );
    const mouseup = fromEvent<MouseEvent>(window, 'mouseup').pipe(
      filter((event) => event.button === 0), // Only allow left button (Primary button)
      map(() => false) // reset threshold counter
    );
    this.subscribeLongPress(mousedown, mouseup, touchstart, touchEnd);
  }

  private subscribeLongPress(mousedown, mouseup, touchstart, touchEnd): void {
    this.eventSubscribe = merge(mousedown, mouseup, touchstart, touchEnd)
      .pipe(
        switchMap((state) => (state ? timer(this.threshold, 100) : of(null))),
        filter((value) => value),
        first()
      )
      .subscribe(() => {
        this.longPress.emit();
        this.eventSubscribeOff = merge(mousedown, mouseup, touchstart, touchEnd)
          .pipe(
            switchMap((state) => (state ? timer(this.threshold, 100) : of(null))),
            filter((value) => !value),
            first()
          )
          .subscribe(() => {
            this.subscribeLongPress(mousedown, mouseup, touchstart, touchEnd);
          });
      });
  }

  ngOnDestroy(): void {
    if (this.eventSubscribe) {
      this.eventSubscribe.unsubscribe();
    }
    if (this.eventSubscribeOff) {
      this.eventSubscribeOff.unsubscribe();
    }
  }
}
