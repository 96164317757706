import { Pipe, PipeTransform } from '@angular/core';
import {Event} from '@app/02-core/models/event.model';

@Pipe({ name: 'eventStatus' })
export class EventStatusPipe implements PipeTransform {
  constructor(
  ) {}
  transform(event: Event): string {
    if (event.finishedAt) {
      return 'Hotovo';
    }
    if (event.startedAt && !event.finishedAt) {
      if (event.media?.isPaused) {
        return 'Pozastaveno';
      } else {
        return 'Probíhá';
      }
    }
    return 'Připraveno';
  }
}
